import moment from 'moment';
import * as tz from 'moment-timezone';

import { TimeScale } from './TimeScale';
import { Dates } from '../api/query/outline/context/Dates';
import { Resolution } from '../data/Resolution';
import { ArrayUtils } from '../util/ArrayUtils';
import Moment = moment.Moment;
import { Injectable, InjectionToken } from '@angular/core';

export const TimeNavigatorToken = new InjectionToken<TimeNavigator>('time-navigator');

@Injectable()
export class TimeNavigator {
  private scaleIndex: number;

  constructor(private scales: TimeScale[]) {
    this.reset();
  }

  public resetTo(now?: Moment) {
    this.scaleIndex = 0;
    this.scales.forEach((scale, i) => {
      scale.reset();
      if (scale.isUsedToInitialize && now != null) {
        scale.setSingleValue(now);
        this.scaleIndex = i;
      }
    });
  }

  public reset(zoneId?: string) {
    this.resetTo(zoneId != null ? tz.tz(zoneId) : moment());
  }

  public getScale() {
    return this.scales[this.scaleIndex];
  }

  private getNextScale() {
    return this.scales[this.scaleIndex + 1];
  }

  public canZoom(amount: number): boolean {
    return (
      this.scaleIndex + amount >= 0 &&
      this.scaleIndex + amount < this.scales.length - 1 &&
      this.scales[this.scaleIndex + amount].hasValues()
    );
  }

  public zoom(amount: number) {
    if (!this.canZoom(amount)) {
      return;
    }

    this.scaleIndex += amount;
  }

  public canShift(amount?: number): boolean {
    return this.isSingleMoment(this.scaleIndex);
  }

  public shift(amount: number) {
    if (!this.canShift(amount)) {
      return;
    }

    const time = this.getSingleMoment();
    this.getScale().shiftMoment(time, amount);
    this.setSingleMoment(time);
  }

  public canSelect(): boolean {
    return this.scaleIndex < this.scales.length - 1;
  }

  public select(blocks: number[]) {
    if (!this.canSelect()) {
      return;
    }

    this.getNextScale().select(blocks);
  }

  public isSingleMoment(endIndex?: number): boolean {
    const scales = endIndex != null ? this.scales.slice(0, endIndex + 1) : this.scales;
    return ArrayUtils.all(scales, (scale) => !scale.hasValues() || scale.isSingleValue());
  }

  public getSingleMoment(): Moment {
    const time = moment();
    this.scales.filter((scale) => scale.isSingleValue()).forEach((scale) => scale.updateMoment(time));
    return time;
  }

  public setSingleMoment(time: Moment) {
    this.scales.filter((scale) => scale.isSingleValue()).forEach((scale) => scale.setSingleValue(time));
  }

  public setDates(dates: Dates) {
    this.scales.forEach((scale) => scale.fromDates(dates));
  }

  public getDates(): Dates {
    const dates = new Dates();
    const includeCurrentScale = 1;
    const includeScaleDisplayed = 1;
    this.scales
      .slice(0, this.scaleIndex + includeCurrentScale + includeScaleDisplayed)
      .forEach((scale) => scale.toDates(dates));
    return dates;
  }

  public getResolution(): Resolution {
    return this.getScale().resolution;
  }

  public setResolution(resolution: Resolution) {
    if (resolution != null) {
      this.scaleIndex = this.scales.map((scale) => scale.resolution).indexOf(resolution);
    }
  }

  public getPointLabel(value: number): string {
    return this.getScale().getPointLabel(value);
  }

  public isActive(value: number): boolean {
    return this.getNextScale() ? this.getNextScale().isActive(value) : false;
  }

  public getLabel(): string {
    if (this.isSingleMoment(this.scaleIndex)) {
      return this.getSingleMoment().format(this.getScale().longFormat);
    }

    return this.getScale().getLabel(this.getSingleMoment());
  }

  public addScale(scale: TimeScale) {
    this.scales.push(scale);
  }

  public toString(): string {
    return this.scales.map((scale) => scale.resolution.toString() + ': ' + scale.getValues().join(', ')).join(' ');
  }
}
