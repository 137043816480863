@if (showHidden) {
  <div class='or-nodes-list'>
    <div class='or-nodes-list-control'>
      <app-nodes-filter
        [filterForm]="filterForm"
        [sortByFields]="sortByFields"
        (filterChange)="onFilterChanged($event)"
        (copyNodesToClipboard)="copyToClipboard()"
        (refreshNodeListEmitter)="refreshNodeList()"></app-nodes-filter>
    </div>
    @if(dataSource.isLoading$ | async) {
      <div class="mat-spinner spinner-container">
        <mat-spinner diameter="80" strokeWidth="8"></mat-spinner>
      </div>
    }
    <div class='nodes-table-parent'>
      <mat-table [dataSource]='dataSource' multiTemplateDataRows matSort>
        <ng-container matColumnDef="nodeStatus">
          <mat-header-cell class="mat-column-header mat-column-item-header center" *matHeaderCellDef> Node Status </mat-header-cell>
          <mat-cell class="mat-row-cell node-address center" *matCellDef="let nodeInfo">
            <div [attr.data-cy]="'node-status-' + (nodeInfo.address || '').toString(16)" class='py-4'>
                  <span [ngClass]="getNodeStatusAndTypeClass(nodeInfo)">
                    <span [ngClass]="getNodeStatusIconClass(nodeInfo)"></span>
                  </span>
              <br>
              @if (!nodeInfo.expanded) {
                <div>
                  @for(tag of nodeInfo.tags; track tag.name) {
                    <div class="or-node-tile-tag">
                      <span class="or-node-tile-tag-thumbnail" title="{{ tag.name }}" [ngStyle]='{ "background-color": "#" + tag.color }'></span>
                    </div>
                  }
                </div>
              }
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
          <mat-header-cell class="mat-column-header mat-column-item-header center" *matHeaderCellDef> Id </mat-header-cell>
          <mat-cell class="mat-row-cell node-address center" *matCellDef="let nodeInfo">
            <div [attr.data-cy]="'node-id-' + nodeInfo.id">
              {{ nodeInfo.id }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="groupId">
          <mat-header-cell class="mat-column-header mat-column-item-header center" *matHeaderCellDef> Group Id </mat-header-cell>
          <mat-cell class="mat-row-cell node-address center" *matCellDef="let nodeInfo">
            <div [attr.data-cy]="'group-id-' + (nodeInfo.address || '').toString(16)">
              {{ nodeInfo.groupId || 'NA' }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="address">
          <mat-header-cell class="mat-column-header mat-column-item-header center" *matHeaderCellDef> Address </mat-header-cell>
          <mat-cell class="mat-row-cell node-address center" *matCellDef="let nodeInfo">
            <div [attr.data-cy]="'node-address-' + (nodeInfo.address || '').toString(16)">
              {{ (nodeInfo.address || '').toString(16).toLocaleUpperCase() }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="burnHours">
          <mat-header-cell class="mat-column-header mat-column-item-header center" *matHeaderCellDef> Burn Hours </mat-header-cell>
          <mat-cell class="mat-row-cell center" *matCellDef="let nodeInfo">
            <div [attr.data-cy]="'burn-hours-' + (nodeInfo.address || '').toString(16)">
              {{ getPropValForLuminaire(nodeInfo, 'burnInHours') }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="energyConsumption">
          <mat-header-cell class="mat-column-header mat-column-item-header center" *matHeaderCellDef> Energy Consumption </mat-header-cell>
          <mat-cell class="mat-row-cell center" *matCellDef="let nodeInfo">
            <div [attr.data-cy]="'energy-consumption-' + (nodeInfo.address || '').toString(16)">
              {{ getPropValForLuminaire(nodeInfo, 'energyConsumption') === 'N/A' ? 'N/A' : getPropValForLuminaire(nodeInfo, 'energyConsumption') + 'kWh' }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="bleScanning">
          <mat-header-cell class="mat-column-header mat-column-item-header center" *matHeaderCellDef> Ble Scanning </mat-header-cell>
          <mat-cell class="mat-row-cell center" *matCellDef="let nodeInfo">
            <div [attr.data-cy]="'ble-scanning-' + (nodeInfo.address || '').toString(16)">
              <img [ngClass]='bleScanningClass(nodeInfo)'/>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="devices">
          <mat-header-cell class="mat-column-header mat-column-item-header center" *matHeaderCellDef> Devices </mat-header-cell>
          <mat-cell class="mat-row-cell center" *matCellDef="let nodeInfo">
            <div [attr.data-cy]="'node-devices-' + (nodeInfo.address || '').toString(16)">
              <ul>
                <li>
                  <span class="or-icon or-tiny or-icon-node"></span>
                  <span class="or-node-tile-value or-node-tile-inline-text">{{nodeInfo.luminaireDrivers?.length}}</span>
                </li>
                <li>
                  <span class="or-icon or-tiny or-icon-emergency"></span>
                  <span class="or-node-tile-value or-node-tile-inline-text">{{nodeInfo.emDrivers?.length}}</span>
                </li>
              </ul>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns; sticky: true" class="mat-column-header"></mat-header-row>
        <mat-row [id]='row.id' #mainTableRows
                 *matRowDef="let row; columns: tableColumns"
                 (click)='toggleRow(row)'
                 [ngClass]="{ 'row-selected': row.selected, 'row-not-expanded:': row.expanded}">
        </mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']"
                 class='inner-table-row'
                 [ngClass]="{ 'row-expanded': row.expanded, 'subnode-row-selected': row.selected}">
        </mat-row>
        <ng-container matColumnDef="expandedDetail">
          <mat-cell *matCellDef="let nodeInfo" [attr.colspan]="tableColumns.length">
            <div class="element-detail"
                 [@detailExpand]="nodeInfo.expanded ? 'expanded' : 'collapsed'">
              <div class="inner-table">
                @if (nodeInfo.tags && nodeInfo.tags.length > 0) {
                  <mat-table #innerTables #innerSort="matSort" [dataSource]="['tags']" matSort>
                    @for (tagColumn of tagColumns; track tagColumn) {
                      <ng-container matColumnDef="{{tagColumn}}">
                        <mat-cell *matCellDef="let element">
                          @for (tag of nodeInfo.tags; track tag.id) {
                            <div class="or-node-tile-details-tags-item">
                                <span class="or-node-tile-details-tag" [ngStyle]="getForegroundColor(tag.color)">
                                  <span class="or-node-tile-details-tag-name">{{tag.name}}</span>
                                </span>
                            </div>
                          }
                        </mat-cell>
                      </ng-container>
                    }
                    <mat-row *matRowDef="let row; columns: tagColumns;"></mat-row>
                  </mat-table>
                }
                @if (nodeInfo?.alerts?.length !== 0) {
                  @for (alert of nodeInfo.alerts; track $index) {
                    <div class="node-alerts py-2 px-6 m-1">
                      @if (alert.link != null) {
                        <a href="{{alert.link}}">
                          {{alert.value}}
                        </a>
                      } @else {
                        {{alert.value}}
                      }
                    </div>
                  }
                }
                @if (nodeInfo.luminaireDrivers && nodeInfo.luminaireDrivers.length > 0) {
                  <mat-table #innerTables #innerSort="matSort" [dataSource]="nodeInfo.luminaireDrivers" matSort class='px-10'>
                    @for (innerColumn of subTableColumns; track innerColumn) {
                      <ng-container matColumnDef="{{innerColumn}}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{innerColumn}} </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                          <div [attr.data-cy]="getDataCy(innerColumn, element[innerColumn])">
                            @if (innerColumn === 'type') {
                              <span class="{{'or-luminaire-driver-tile-status-body or-luminaire-driver-tile-status-body-' + element.status}}">
                                  <span class="or-icon or-tiny or-icon-node"></span>
                                </span>
                            } @else {
                              {{getProperValue(innerColumn, element[innerColumn])}}
                            }
                            <br>
                            <span class="or-node-tile-label">{{innerColumn}}</span>
                          </div>
                        </mat-cell>
                      </ng-container>
                    }
                    <mat-row *matRowDef="let row; columns: subTableColumns;"></mat-row>
                  </mat-table>
                }
                @if (nodeInfo.emDrivers && nodeInfo.emDrivers.length > 0) {
                  <mat-divider></mat-divider>
                  <mat-table #innerTables #innerSort="matSort" [dataSource]="nodeInfo.emDrivers" matSort class='px-10'>
                    @for (innerColumn of subTableColumns; track innerColumn) {
                      <ng-container matColumnDef="{{innerColumn}}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{innerColumn}} </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                          <div [attr.data-cy]="getDataCy(innerColumn, element[innerColumn])">
                            @if (innerColumn === 'type') {
                              <span class="{{'or-em-driver-tile-status-body or-em-driver-tile-status-body-' + element.status}}">
                                  <span class="or-icon or-tiny or-icon-emergency"></span>
                                </span>
                            } @else {
                              {{getProperValue(innerColumn, element[innerColumn])}}
                            }
                            <br>
                            <span class="or-node-tile-label">{{innerColumn}}</span>
                          </div>
                        </mat-cell>
                      </ng-container>
                    }
                    <mat-row *matRowDef="let row; columns: subTableColumns;"></mat-row>
                  </mat-table>
                }
              </div>
            </div>
          </mat-cell>
        </ng-container>
      </mat-table>
    </div>
  </div>
}

