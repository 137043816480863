import { QueryResult } from './../api/query/QueryResult';
import { IQueryExecutor, QueryExecutorConfiguration } from '../api/query/IQueryExecutor';
import { ComplexQueryOutline } from '../api/query/outline/ComplexQueryOutline';
import { LiveQueryOutline } from '../api/query/outline/LiveQueryOutline';
import { PromiseUtils } from './promises/PromiseUtils';
import { PromiseRejectionHandler } from '../promises/PromiseRejectionHandler';
import angular from 'angular';
import { LiveDataQueryResults } from '@angularjs/or/api/query/LiveDataQueryResults';
import { FeatureService } from '@services/feature.service';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

const Deferred = function () {
  this.promise = new Promise(
    function (resolve, reject) {
      this.resolve = resolve;
      this.reject = reject;
    }.bind(this)
  );
  this.then = this.promise.then.bind(this.promise);
  this.catch = this.promise.catch.bind(this.promise);
};

export class QueryExecutor implements IQueryExecutor {
  private canceller;
  private nodeDataCanceller;

  private simpleQueryUrl: string;
  private complexQueryUrl: string;
  private liveQueryUrl: string;
  private liveDataQueryUrl: string;

  constructor(
    private $http: angular.IHttpService,
    private configuration: QueryExecutorConfiguration,
    private rejectionHandler: PromiseRejectionHandler,
    private featureService: FeatureService
  ) {}

  private setupUrls(): Promise<void> {
    return lastValueFrom(
      this.featureService.isAvailable('influx').pipe(
        map((check) => {
          if (check) {
            this.simpleQueryUrl = this.configuration.simpleQueryUrl.replace('query', 'analytics');
            this.complexQueryUrl = this.configuration.complexQueryUrl.replace('query', 'analytics');
            this.liveQueryUrl = this.configuration.liveQueryUrl.replace('query', 'analytics');
            this.liveDataQueryUrl = this.configuration.liveDataQueryUrl.replace('query', 'analytics');
          } else {
            this.simpleQueryUrl = this.configuration.simpleQueryUrl;
            this.complexQueryUrl = this.configuration.complexQueryUrl;
            this.liveQueryUrl = this.configuration.liveQueryUrl;
            this.liveDataQueryUrl = this.configuration.liveDataQueryUrl;
          }
        })
      )
    );
  }

  public doComplexQuery(outline: ComplexQueryOutline): Promise<QueryResult<number>> {
    if (this.complexQueryUrl == null) {
      return this.setupUrls().then(() => {
        const url = this.complexQueryUrl;
        return PromiseUtils.wrapSingle(this.$http.post(url, outline), this.rejectionHandler);
      });
    } else {
      const url = this.complexQueryUrl;
      return PromiseUtils.wrapSingle(this.$http.post(url, outline), this.rejectionHandler);
    }
  }

  public doLiveQuery(outline: LiveQueryOutline): Promise<QueryResult<number>> {
    this.canceller = new Deferred();
    if (this.liveQueryUrl == null) {
      return this.setupUrls().then(() => this.liveQuery(outline));
    } else {
      return this.liveQuery(outline);
    }
  }

  private liveQuery(outline: LiveQueryOutline): Promise<QueryResult<number>> {
    const url = this.liveQueryUrl;
    return PromiseUtils.wrapSingle(
      this.$http.post(url, outline, {
        timeout: this.canceller.promise
      }),
      this.rejectionHandler
    );
  }

  public cancelLiveQuery(): void {
    this.canceller?.resolve();
  }

  public cancelNodeDataQuery(): void {
    this.nodeDataCanceller?.resolve();
  }

  doLiveDataQuery(outline: LiveQueryOutline): Promise<LiveDataQueryResults> {
    this.nodeDataCanceller = new Deferred();
    if (this.liveDataQueryUrl == null) {
      return this.setupUrls().then(() => {
        const url = this.liveDataQueryUrl;
        return PromiseUtils.wrapSingle(
          this.$http.post(url, outline, {
            timeout: this.nodeDataCanceller.promise
          }),
          this.rejectionHandler
        );
      });
    } else {
      const url = this.liveDataQueryUrl;
      return PromiseUtils.wrapSingle(
        this.$http.post(url, outline, {
          timeout: this.nodeDataCanceller.promise
        }),
        this.rejectionHandler
      );
    }
  }
}
