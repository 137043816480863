import { FeatureService } from '@app/shared/services/feature.service';
import { IAttributes, IAugmentedJQuery, IScope, IDirective } from 'angular';

export class FeatureFlagDirective {
  public static create(featureService: FeatureService): IDirective {
    const linkDirective = (_scope: IScope, element: IAugmentedJQuery, attributes: IAttributes) => {
      if (attributes.featureFlag != null) {
        const prevDisplay = element.css('display');
        element.css('display', 'none');
        featureService.isAvailable(attributes.featureFlag).subscribe((isAvailable) => {
          if (!isAvailable) {
            element.remove();
          } else {
            element.css('display', prevDisplay);
          }
        });
      }
    };

    return {
      link: linkDirective,
      bindToController: false,
      controllerAs: 'featureFlag',
      scope: false,
      restrict: 'A'
    };
  }
}
