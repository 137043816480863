import { Tag } from './Tag';
import { Map } from '../../util/Map';
import { BeaconSetting } from './BeaconSetting';
import { LightingConfiguration } from './LightingConfiguration';
import { DuplicateMapping } from '@angularjs/or/api/building/DuplicateMapping';
import { EmDriver } from '@angularjs/or/api/building/EmDriver';
import { LuminaireDriver } from '@angularjs/or/api/building/LuminaireDriver';
import { ISelectable } from '@angularjs/or/api/building/ISelectable';
import { DISCRIMINATOR } from '@app/shared/models/selectable.interface';

export class SensorNode implements ISelectable {
  constructor(
    public id?: number,
    public x?: number,
    public y?: number,
    public tags?: Tag[],
    public tenantIds?: number[],
    public floorId?: number,
    public address?: number,
    public connected?: boolean,
    public installedAt?: Date,
    public bleSeq?: number,
    public bleKey?: number,
    public duplicateAddressMappings?: DuplicateMapping[],
    public duplicateGroupMappings?: DuplicateMapping[],
    public isHighlighted: boolean = false,
    public isChanged: boolean = false,
    public beaconSetting?: BeaconSetting,
    public lightingConfiguration?: LightingConfiguration,
    public lightLevel?: number,
    public scene?: number,
    public presence?: number,
    public properlyMapped?: boolean,
    public emDrivers?: EmDriver[],
    public luminaireDrivers?: LuminaireDriver[],
    public nodeType?: string,
    public groupId?: number,
    public subscriber?: boolean,
    public bleScanning?: string
  ) {}

  public static PASSIVE_NODE_TYPE = DISCRIMINATOR.PN.toString();
  public static SN3_NODE_TYPE = DISCRIMINATOR.SN3.toString();
  public static HIM84_NODE_TYPE = DISCRIMINATOR.HIM84.toString();
  public static FUJITSU_ENV = DISCRIMINATOR.FUJITSU_ENV.toString();
  public static FUJITSU_ENV_C02 = DISCRIMINATOR.FUJITSU_ENV_CO2.toString();

  public static from(other: SensorNode): SensorNode {
    return new SensorNode(
      other.id,
      other.x,
      other.y,
      other.tags,
      other.tenantIds,
      other.floorId,
      other.address,
      other.connected,
      other.installedAt,
      other.bleSeq,
      other.bleKey,
      other.duplicateAddressMappings,
      other.duplicateGroupMappings,
      other.isHighlighted,
      other.isChanged,
      other.beaconSetting,
      other.lightingConfiguration,
      other.lightLevel,
      other.scene,
      other.presence,
      other.properlyMapped,
      other.emDrivers,
      other.luminaireDrivers,
      other.nodeType,
      other.groupId,
      other.subscriber,
      other.bleScanning
    );
  }

  public get timeSinceInstalled(): number {
    return this.installedAt ? (new Date().getTime() - this.installedAt.getTime()) / 1000 / 60 / 60 : null;
  }

  public static isSN3Node(node: SensorNode): boolean {
    // If the nodeType is undefined, assume that it is a SN3_NODE_TYPE
    return !node.nodeType || node.nodeType === this.SN3_NODE_TYPE;
  }
  public static isPassiveNode(node: SensorNode): boolean {
    return node.nodeType === this.PASSIVE_NODE_TYPE;
  }

  public static isHIM84Node(node: SensorNode): boolean {
    return node.nodeType === this.HIM84_NODE_TYPE;
  }

  public static isEnvironmentalSensor(node: SensorNode): boolean {
    return [this.FUJITSU_ENV, this.FUJITSU_ENV_C02].includes(node.nodeType);
  }

  public static isMapped(node: SensorNode): boolean {
    return node.address != null;
  }

  public static showDrivers(node: SensorNode): boolean {
    return node.emDrivers != null || node.luminaireDrivers != null;
  }

  public static getTotalDaliDriver(node: SensorNode): number {
    if (!node.properlyMapped) {
      return 0;
    }
    return node.luminaireDrivers?.length;
  }

  public static getTotalEMDriver(node: SensorNode): number {
    if (!node.properlyMapped) {
      return 0;
    }
    return node.emDrivers?.length;
  }

  public static getOrderableProperties(): Map<string> {
    return {
      id: 'Id',
      address: 'Address',
      installedAt: 'Time Since Installation'
    } as Map<string>;
  }

  public static getNodeTypes(): Map<string> {
    return {
      '': '-- Choose a Type --',
      PN: 'Passive Node',
      SN3: 'Sensor Node'
    };
  }

  public static getBeaconsOrderableProperties(): Map<string> {
    return {
      id: 'Id',
      address: 'Address',
      beaconEnabled: 'Enabled',
      beaconPowerLevelNum: 'Power Level',
      beaconContentStr: 'Beacon Content',
      beaconIntervalStr: 'Beaconing Repetition'
    } as Map<string>;
  }

  public static getLightingConfigurationOrderableProperties(): Map<string> {
    return {
      id: 'ID',
      address: 'Address',
      lightingConfigurationZone: 'Zone'
    } as Map<string>;
  }

  public formatLightLevel(): number {
    return this.lightLevel && this.lightLevel > 0 ? Math.round(this.lightLevel) : 0;
  }

  public formatPresenceLevel(): number {
    return this.presence && this.presence > 0 ? Math.round(this.presence * 100) / 100 : 0;
  }

  public get bleScanningState(): string {
    if (this.nodeType === SensorNode.PASSIVE_NODE_TYPE) {
      return 'ON';
    } else {
      return this.bleScanning != null ? this.bleScanning : 'Unknown, please query';
    }
  }

  public get bleScanningClass(): string {
    if (this.bleScanning === 'ON' || this.nodeType === SensorNode.PASSIVE_NODE_TYPE) {
      return 'or-icon-ble-status-on';
    } else if (this.bleScanning == null) {
      return 'or-icon-ble-status-unknown';
    } else {
      return 'or-icon-ble-status-off';
    }
  }

  public get bleScanningTitle(): string {
    if (this.bleScanning === 'ON' || this.nodeType === SensorNode.PASSIVE_NODE_TYPE) {
      return 'BLE Scanning On';
    } else if (this.bleScanning == null) {
      return 'BLE Scanning Unknown, please query';
    } else {
      return 'BLE Scanning Off';
    }
  }
}

export class MeshNode {
  constructor(
    public bacnetDeviceId: number,
    public bacnetObjectId: number,
    public bacnetDeviceName: string,
    public bacnetObjectName: string,
    public crestronAddress: string,
    public reachable: boolean,
    public relay: boolean,
    public maxRelayRandomize: number,
    public proxy: boolean,
    public ttl: number
  ) {}
}
