import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureResource } from '@app/shared/resources/feature.resource';
import { NavigationService } from '@services/navigation/navigation.service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
/*
  this service can be used either as a directive to remove elements
  or via the service directly.

  ng+ Directive: isFeatureAvailable="Key of Feature in string"
  AngularJS Directive: is-feature-available="Key of Feature in string" (Is part of the IsAuthorized Directive)
 */
export class FeatureService {
  constructor(private featureResource: FeatureResource, private navService: NavigationService) {}

  private buildingId: number;
  private featuresList: Observable<string[]>;

  isAvailable(key: string): Observable<boolean> {
    const newBuildingId = this.navService.buildingId;
    if (newBuildingId == null) {
      this.buildingId = null;
      return this.featureResource.getFeature(key);
    } else if (newBuildingId !== this.buildingId) {
      this.buildingId = newBuildingId;
      this.featuresList = this.featureResource.getAllFeaturesForBuilding(newBuildingId).pipe(shareReplay());
      return this.featuresList.pipe(map((list) => !list.includes(key)));
    } else {
      return this.featuresList.pipe(map((list) => !list.includes(key)));
    }
  }
}
